import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import IconLoader from "@/features/Common/components/IconLoader";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import { convertPxToRem } from "@/utils";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface AddDependentsThankYouProps {
  onDone: () => void;
}

export default function AddDependentsThankYou({
  onDone,
}: AddDependentsThankYouProps) {
  const { t } = useTranslation();

  function onDoneButtonClick() {
    onDone();
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Membership:Add dependents success",
      pageSubSection1: "Membership",
      pageSubSection2: "Membership:Add dependents success",
      pageCategory: "Membership",
    });
  }, []);

  return (
    <>
      <Stack
        justifyContent="flex-end"
        color="primary.contrastText"
        spacing={4}
        p={2}
        sx={{
          height: "100%",
        }}
      >
        <IconLoader
          icon="AddPeopleIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />

        <Stack textAlign="center" spacing={4} py={4}>
          <Typography variant="h1" color="inherit">
            {t("AddDependents.title")}
          </Typography>

          <Typography variant="h3" color="inherit">
            {t("AddDependents.subTitle")}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <ButtonWithAnalytics
            page="Add dependents success"
            text={t("common.done")}
            intent="informational"
            onClick={onDoneButtonClick}
            variant="outlined"
            color="info"
            fullWidth
          >
            {t("common.done")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>
      <FullscreenBackground color="primary.main" />
    </>
  );
}
