import { Box } from "@mui/material";
import MembershipAddDependentsForm from ".";

export default function MembershipAddDependentsFormExamples() {
  function onCompletedFlow() {
    console.log("onCompletedFlow");
  }

  return (
    <Box sx={{ p: 2 }}>
      <MembershipAddDependentsForm onCompletedFlow={onCompletedFlow} />
    </Box>
  );
}
