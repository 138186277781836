import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SetStateAction, useEffect, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import CircledBackButton from "@/features/Common/components/CircledBackButton";
import FullscreenLoadingIndicator from "@/features/Common/components/FullscreenLoadingIndicator";
import IdentificationNumberToggle from "@/features/Common/components/IdentificationNumberToggle";
import PassportCountryOfIssueCode from "@/features/Common/components/PassportCountryOfIssueCode";
import {
  AnalyticsEvent,
  trackFormEvent,
  trackPageViewV2,
} from "@/services/analytics-adapter";
import {
  IdentityDocumentType,
  MembershipDependent,
  MembershipDependentIdentityDocumentType,
} from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { styleInputDefault } from "@/theme";
import { FaceIconBrandLarge } from "@/theme/icons";
import { convertPxToRem, getDateOfBirthFromZAIdNumber } from "@/utils";
import { PassportCountryCode } from "@/utils/countries";
import { isValid as isValidZAIDNumber } from "@/utils/validators/ZAIDNumber/ZAIDNumber";
import {
  isValid,
  isValid as isValidInternationalPassportNumber,
} from "@/utils/validators/common";
import DateOfBirthInput, { DOBFormData } from "@/features/Common/components/DateOfBirthInput";
import FormInputErrorList from "@/features/Common/components/FormInputErrorList";

interface MembershipAddDependentsFormProps {
  onCompletedFlow?: (dependents: MembershipDependent) => void;
  selectedDependent?: MembershipDependent | null;
}

export default function MembershipAddDependentsForm({
  onCompletedFlow,
  selectedDependent,
}: MembershipAddDependentsFormProps) {
  const { t } = useTranslation();
  const firstNameInputID = useId();
  const lastNameInputID = useId();
  const sexAtBirthInputID = useId();
  const maxDependentAge = 18;

  const [firstName, setFirstName] = useState(
    selectedDependent?.firstName || ""
  );
  const [lastName, setLastName] = useState(selectedDependent?.lastName || "");
  const [identityDocumentCountryOfIssue, setIdentityDocumentCountryOfIssue] =
    useState<string | null>(
      selectedDependent?.identityDocumentCountryOfIssue || null
    );
  const [dateOfBirth, setDateOfBirth] = useState(
    selectedDependent?.dateOfBirth || ""
  );
  const [isValidDateOfBirth, setIsValidDateOfBirth] = useState(false);

  const [isDependentOverMaxAllowedAge, setIsDependentOverMaxAllowedAge] =
    useState(false);
  const [sexAtBirth, setSexAtBirth] = useState(
    transformSexAtBirthToExpectedValue(selectedDependent?.sexAtBirth || "M") ||
      "Male"
  );
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [identificationType, setIdentificationType] =
    useState<IdentityDocumentType>(
      selectedDependent?.identityDocumentType || IdentityDocumentType.IdNumber
    );
  const [identificationValue, setIdentificationValue] = useState(
    selectedDependent?.identityDocumentValue || ""
  );

  const shouldCollectIdentityDocumentDetails = !isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_ADD_DEPENDANTS_WITHOUT_IDENTITY_DOCUMENT_DETAILS
  );

  function transformSexAtBirthToExpectedValue(value: string) {
    switch (value.toLowerCase()) {
      case "m":
        return "Male";
      case "f":
        return "Female";
      default:
        return value;
    }
  }

  function handleIdentificationToggleChange(
    type: SetStateAction<IdentityDocumentType>,
    value: SetStateAction<string>
  ) {
    setIdentificationType(type);
    setIdentificationValue(value);
  }

  function updateSubmitButtonEnabledState(value: any) {
    setIsSubmitButtonEnabled(isValid(value));
  }

  useEffect(() => {
    if (shouldCollectIdentityDocumentDetails === false) {
      updateSubmitButtonEnabledState(
        isValidDateOfBirth && firstName && lastName && dateOfBirth && sexAtBirth
      );
    } else if (shouldCollectIdentityDocumentDetails === true) {
      if (identificationType === IdentityDocumentType.PassportNumber) {
        updateSubmitButtonEnabledState(
          isValidDateOfBirth &&
            identificationValue &&
            firstName &&
            lastName &&
            dateOfBirth &&
            sexAtBirth &&
            identityDocumentCountryOfIssue
        );
      } else {
        updateSubmitButtonEnabledState(
          isDependentOverMaxAllowedAge &&
            identificationValue &&
            firstName &&
            lastName
        );
      }
    }
  }, [
    identityDocumentCountryOfIssue,
    identificationValue,
    firstName,
    lastName,
    dateOfBirth,
    sexAtBirth,
    isValidDateOfBirth,
    isDependentOverMaxAllowedAge,
  ]);

  function onFirstNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setFirstName(value);
  }

  function onLastNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setLastName(value);
  }

  function onDateOfBirthInput(
    data: DOBFormData,
    validatedTotalDate: boolean
  ): void {
    if (validatedTotalDate) {
      const dateOfBirth = `${data.year}-${data.month.padStart(
        2,
        "0"
      )}-${data.day.padStart(2, "0")}`;
      setDateOfBirth(dateOfBirth);
    } else {
      setDateOfBirth("");
    }
  }

  function dateOfBirthValidation(dateOfBirth: string): boolean {
    const currentDate = new Date();
    const _dateOfBirth = new Date(dateOfBirth);
    let age = currentDate.getFullYear() - _dateOfBirth.getFullYear();
    if (
      currentDate.getMonth() < _dateOfBirth.getMonth() ||
      (currentDate.getMonth() === _dateOfBirth.getMonth() &&
        currentDate.getDate() < _dateOfBirth.getDate())
    ) {
      age--;
    }
    if (age > maxDependentAge - 1) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    setIsValidDateOfBirth(dateOfBirthValidation(dateOfBirth));
  }, [dateOfBirth, isValidDateOfBirth]);

  function isValidIdentification(
    _identificationType: string,
    _identificationValue: string
  ) {
    switch (_identificationType) {
      case "IdNumber":
        return isValidZAIDNumber(_identificationValue);
      case "PassportNumber":
        return isValidInternationalPassportNumber(_identificationValue);
    }
    return false;
  }

  function checkIsDependentOverMaxAllowedAge(
    identificationType: string,
    identificationValue: string
  ): boolean {
    if (
      identificationType === IdentityDocumentType.IdNumber &&
      isValidIdentification(identificationType, identificationValue)
    ) {
      const formattedDate = getDateOfBirthFromZAIdNumber(identificationValue);
      const birthDate = new Date(formattedDate);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();
      if (age > maxDependentAge - 1) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  const handleCountryCodeChange = (
    selectedCountry: PassportCountryCode | null
  ) => {
    setIdentityDocumentCountryOfIssue((selectedCountry as any)?.alpha3 || "");
  };

  useEffect(() => {
    setIsDependentOverMaxAllowedAge(
      checkIsDependentOverMaxAllowedAge(identificationType, identificationValue)
    );
  }, [isDependentOverMaxAllowedAge, identificationType, identificationValue]);

  function onSexAtBirthChange(el: any) {
    setSexAtBirth(el.target.value);
  }

  function transformIdentityDocumentType(
    identityDocumentType: IdentityDocumentType
  ) {
    switch (identityDocumentType) {
      case IdentityDocumentType.IdNumber:
        return MembershipDependentIdentityDocumentType.ID_NUMBER;
      case IdentityDocumentType.PassportNumber:
        return MembershipDependentIdentityDocumentType.PASSPORT_NUMBER;
      default:
        return MembershipDependentIdentityDocumentType.ID_NUMBER;
    }
  }

  function reverseTransformIdentityDocumentType(
    identityDocumentType: MembershipDependentIdentityDocumentType
  ) {
    switch (identityDocumentType) {
      case MembershipDependentIdentityDocumentType.ID_NUMBER:
        return IdentityDocumentType.IdNumber;
      case MembershipDependentIdentityDocumentType.PASSPORT_NUMBER:
        return IdentityDocumentType.PassportNumber;
      default:
        return IdentityDocumentType.IdNumber;
    }
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmittingForm(true);

    const getSelectedDependentData = selectedDependent || {};

    const transformSexAtBirthValue =
      sexAtBirth?.toLowerCase() === "male" ? "M" : "F";

    let formData: any = {
      ...getSelectedDependentData,
      firstName,
      lastName,
      identityDocumentType: transformIdentityDocumentType(
        identificationType
      ) as any,
      identityDocumentValue: identificationValue,
    };

    if (identificationType === IdentityDocumentType.PassportNumber) {
      formData = {
        ...formData,
        dateOfBirth,
        sexAtBirth: transformSexAtBirthValue,
        identityDocumentCountryOfIssue: identityDocumentCountryOfIssue || "",
      };
    }

    if (shouldCollectIdentityDocumentDetails === false) {
      formData = {
        firstName,
        lastName,
        dateOfBirth,
        sexAtBirth: transformSexAtBirthValue,
      };
    }

    onDone(formData);

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Add dependents",
    });
  }

  function onDone(formData: MembershipDependent) {
    if (onCompletedFlow) {
      setIsFormError(false);
      onCompletedFlow(formData);
    }
  }

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Add dependents",
    });

    trackPageViewV2({
      pageName: "Membership:Add dependents",
      pageSubSection1: "Membership",
      pageSubSection2: "Membership:Add dependents",
      pageCategory: "Membership",
    });
  }, []);

  return (
    <>
      <Stack justifyContent="flex-end">
        {isSubmittingForm ? (
          <FullscreenLoadingIndicator />
        ) : (
          <Stack spacing={4}>
            <Stack pb={6}>
              <CircledBackButton showLabel={true} />
            </Stack>
            <form onSubmit={onSubmit}>
              <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
                <Stack spacing={2} sx={{ pb: 3 }}>
                  <FaceIconBrandLarge />
                  <Stack spacing={2}>
                    <Typography component="h1" variant="h3">
                      {t("MembershipAddDependentsForm.title")}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <InputLabel htmlFor={firstNameInputID}>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("FirstNameInput.label")}
                    </Typography>
                  </InputLabel>
                  <TextField
                    id={firstNameInputID}
                    value={firstName}
                    size="small"
                    onInput={onFirstNameInput}
                    inputProps={{
                      autoComplete: "off",
                      "aria-label": t("FirstNameInput.label"),
                    }}
                    sx={{
                      input: {
                        ...styleInputDefault,
                        fontSize: convertPxToRem(16),
                      },
                    }}
                    fullWidth
                  />
                </Stack>
                <Stack spacing={1}>
                  <InputLabel htmlFor={lastNameInputID}>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("LastNameInput.label")}
                    </Typography>
                  </InputLabel>
                  <TextField
                    id={lastNameInputID}
                    value={lastName}
                    size="small"
                    onInput={onLastNameInput}
                    inputProps={{
                      autoComplete: "off",
                      "aria-label": t("LastNameInput.label"),
                    }}
                    sx={{
                      input: {
                        ...styleInputDefault,
                        fontSize: convertPxToRem(16),
                      },
                    }}
                    fullWidth
                  />
                </Stack>

                {shouldCollectIdentityDocumentDetails === true && (
                  <>
                    <Stack pb={1}>
                      <Typography component="h2" variant="h3">
                        {t("MembershipAddDependentsForm.IDNumberTitle")}
                      </Typography>
                    </Stack>
                    <Stack>
                      <IdentificationNumberToggle
                        existingValue={{
                          identificationType:
                            reverseTransformIdentityDocumentType(
                              selectedDependent?.identityDocumentType as any
                            ) || "",
                          identificationValue:
                            selectedDependent?.identityDocumentValue || "",
                        }}
                        showInputLabels={true}
                        onIdentificationToggleChange={
                          handleIdentificationToggleChange
                        }
                        isError={
                          isValidIdentification(
                            identificationType,
                            identificationValue
                          ) && !isDependentOverMaxAllowedAge
                        }
                        errorText={t(
                          "MembershipAddDependentsForm.DateOfBirthError",
                          {
                            maxDependentAge: maxDependentAge,
                          }
                        )}
                      />
                    </Stack>
                  </>
                )}

                {shouldCollectIdentityDocumentDetails === true &&
                  identificationType ===
                    IdentityDocumentType.PassportNumber && (
                    <>
                      <PassportCountryOfIssueCode
                        value={identityDocumentCountryOfIssue as any}
                        onCountryCodeChange={handleCountryCodeChange}
                      />
                    </>
                  )}

                {(shouldCollectIdentityDocumentDetails === false ||
                  identificationType ===
                    IdentityDocumentType.PassportNumber) && (
                  <>
                    <Stack spacing={1}>
                      <InputLabel>
                        <Typography
                          color="neutral.700"
                          variant="body2"
                          fontWeight={300}
                        >
                          {t("DateOfBirthInput.label")}
                        </Typography>
                      </InputLabel>
                      <DateOfBirthInput
                        value={dateOfBirth}
                        onChange={onDateOfBirthInput}
                        placeholder={{
                          day: "DD",
                          month: "MM",
                          year: "YYYY",
                        }}
                        showLabel={false}
                        isError={!isValidDateOfBirth}
                        errorText={t(
                          "MembershipAddDependentsForm.DateOfBirthError",
                          {
                            maxDependentAge: maxDependentAge,
                          }
                        )}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <InputLabel>
                        <Typography
                          color="neutral.700"
                          variant="body2"
                          fontWeight={300}
                        >
                          {t("SexAtBirthInput.label")}
                        </Typography>
                      </InputLabel>
                      <Select
                        id={sexAtBirthInputID}
                        value={sexAtBirth}
                        onChange={onSexAtBirthChange}
                        inputProps={{
                          "aria-label": t("SexAtBirthInput.label"),
                        }}
                        MenuProps={{
                          "aria-label": t("SexAtBirthInput.label"),
                        }}
                      >
                        <MenuItem value={"Male"}>
                          <Stack textAlign={"left"}>{t("common.male")}</Stack>
                        </MenuItem>
                        <MenuItem value={"Female"}>
                          <Stack textAlign={"left"}>{t("common.female")}</Stack>
                        </MenuItem>
                      </Select>
                    </Stack>
                  </>
                )}

                {isFormError && (
                  <FormInputErrorList
                    errors={[t("common.somethingWentWrong")]}
                  />
                )}
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isSubmitButtonEnabled}
                  aria-label={t("common.saveButton")}
                  fullWidth
                >
                  {t("common.saveButton")}
                </Button>
              </Stack>
            </form>
          </Stack>
        )}
      </Stack>
    </>
  );
}
